import { useUsersStore } from '@/stores';
import { toEENTimestamp } from '@/service/timeHelpers';
import type { DateFormatterParams } from '@/service/timeHelpers';

Date.prototype.toEENAccountTimeStamp = function (params?: DateFormatterParams) {
  const {
    timezone = useUsersStore().currentUserTimezone,
    showMilliseconds = useUsersStore().currentUserTimeSettings?.showMilliseconds,
    hour12 = useUsersStore().hour12,
    showDate,
    showTime,
  } = params ?? {
    timezone: useUsersStore().currentUserTimezone,
    showMilliseconds: useUsersStore().currentUserTimeSettings?.showMilliseconds,
    hour12: useUsersStore().hour12,
  };

  return toEENTimestamp({
    date: this,
    timezone,
    showMilliseconds,
    hour12,
    showDate,
    showTime,
  });
};

Date.prototype.toEENCameraDateTimeStamp = function (params?: DateFormatterParams) {
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const {
    timezone = localTimezone,
    showMilliseconds = useUsersStore().currentUserTimeSettings?.showMilliseconds,
    hour12 = useUsersStore().hour12,
    showDate,
    showTime,
    showSec = true,
    showOffsetFromUTC = false,
  } = params ?? {
    timezone: localTimezone,
    showMilliseconds: useUsersStore().currentUserTimeSettings?.showMilliseconds,
    hour12: useUsersStore().hour12,
  };
  return toEENTimestamp({
    date: this,
    timezone,
    showMilliseconds,
    hour12,
    showDate,
    showTime,
    showSec,
    showOffsetFromUTC,
  });
};

/**
 *
 * @returns the date in the format 2022-08-01T07:17:55.115+00:00
 */
Date.prototype.toEENApiDateTime = function () {
  return this.toISOString().replace('Z', '+00:00');
};

export function getGmtTimestamp(val: string) {
  const date = new Date(val);
  const gmt = (date.getTimezoneOffset() * -1) / 60;
  const usersStore = useUsersStore();
  const eenDateTime = date.toEENCameraDateTimeStamp({ timezone: usersStore.currentUserTimezone }).split(' ');
  return {
    dateInfo: eenDateTime[0],
    timeInfo: `${eenDateTime[1].split('.')[0]} ${eenDateTime[2] ?? ''} (GMT${gmt >= 0 ? '+' : ''}${gmt})`,
    time: `${eenDateTime[1].split('.')[0]} ${eenDateTime[2] ?? ''}`,
  };
}

/**
 *
 * @returns the date in the format:
 *   dateInfo: 2025-01-07
 *   timeInfo: 15:00 (UTC +01:00) or 03:00 PM (UTC +01:00)
 *   fullInfo: 2025-01-07 15:00 (UTC +01:00) or 2025-01-07 03:00 PM (UTC +01:00)
 *
 */
export function getCameraTimestamp(val: string, timezone: string | undefined) {
  const date = new Date(val);
  const cameraTimestamp: string | string[] = date.toEENCameraDateTimeStamp({
    showOffsetFromUTC: true,
    showSec: false,
    showMilliseconds: false,
    timezone,
  });

  const dateInfo = cameraTimestamp.slice(0, 10);
  const time = cameraTimestamp.slice(11, 16);
  const offset = cameraTimestamp.slice(16, 22);
  const ampm = cameraTimestamp.slice(23, 25);
  const timeInfo = time + ' ' + ampm + ' (UTC ' + offset + ')';

  return {
    dateInfo: dateInfo,
    timeInfo: timeInfo,
    fullInfo: dateInfo + ' ' + timeInfo,
  };
}
