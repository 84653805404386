import Vue from 'vue';
import VueCookie from 'vue-cookies';
import VueAnalytics from 'vue-analytics';
import { VueQueryPlugin } from '@tanstack/vue-query';
import vuetify from '@/plugins/vuetify';
import router from '@/service/router';
import setupInterceptor from '@/service/interceptor';
import App from './App.vue';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import locale from '@/plugins/i18n';
import eewcComponents from '@eencloud/eewc-components/src';
import './service/time';
import { createPinia, PiniaVuePlugin } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import VueRouter from 'vue-router';
import Maska from 'maska';
import Highcharts from 'highcharts';
import HighchartsVue from 'highcharts-vue';
import StockModule from 'highcharts/modules/stock';
import exportingInit from 'highcharts/modules/exporting';
import exportDataInit from 'highcharts/modules/export-data';
import { ClickOutside } from 'vuetify/lib/directives';
import { Intersect } from 'vuetify/lib/directives';
import { getBrandedBaseUrlFromOrigin, authRedirectUri } from '@/service/branding';
import { useGTM } from '@/service/useGTM';
import PosthogPlugin from '@/plugins/posthog';

// In order to use Highcharts Stock we need to
// wrap Highcharts with the correct module:
StockModule(Highcharts);
exportingInit(Highcharts);
exportDataInit(Highcharts);

/* eslint-disable */
console.log(`Eagle Eye Networks VMS version ${config.version}`);

Vue.use(VueQueryPlugin, {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
});
Vue.use(PiniaVuePlugin);
Vue.use(VueCookie);
Vue.use(VueAnalytics, {
  id: config['google-tag-manager']['tracking-id'],
  router,
  autoTracking: {
    exception: true,
  },
});
Vue.use(VueRouter);
Vue.use(eewcComponents);
Vue.use(Maska);
Vue.use(PosthogPlugin);
Vue.use(HighchartsVue);
Vue.directive('click-outside', ClickOutside); // not automatically resolved by vite
Vue.directive('intersect', Intersect); // not automatically resolved by vite

setupInterceptor();
const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

const firebaseConfig = {
  apiKey: 'AIzaSyBRwDbcOuXdIoDktPl8eUMdahS4qlIsizo',
  authDomain: 'een-web-vms.firebaseapp.com',
  projectId: 'een-web-vms',
  storageBucket: 'een-web-vms.appspot.com',
  messagingSenderId: '430791486562',
  appId: '1:430791486562:web:66546b98b143e9073eb53b',
  measurementId: 'G-TM3TCRQFN0',
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const i18n = locale.i18n;
// initialize google tag manager
useGTM('GTM-W657LPVB');

new Vue({
  router,
  vuetify,
  i18n,
  pinia,
  render: (h) => h(App),
  mounted() {
    // Remove the spinner when the app is mounted
    const spinnerContainer = document.getElementById('spinner');
    spinnerContainer.parentNode.removeChild(spinnerContainer);
  },
}).$mount('#app');

Vue.config.productionTip = false;

// in case the user is unauthenticated, we need to determine the correct auth server + redirect uri
config.authServer = getBrandedBaseUrlFromOrigin();
config.redirectUri = authRedirectUri();

window.jpegStreamParser = new Worker(new URL('./service/workers/jpegParser.js', import.meta.url));
window.csvParser = new Worker(new URL('./service/workers/csvParser.ts', import.meta.url));
