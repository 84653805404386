/**
 * Pinia store for managing Server-Sent Events (SSE) subscriptions.
 * This store handles the creation, updating, and removal of event subscriptions,
 * manages SSE streams, and interacts with APIs for event filters and subscriptions.
 */
import { defineStore } from 'pinia';
import { computed, ref, watch } from 'vue';
import { useEventsStore } from './events';
import { SSEService } from '@eencloud/eewc-components/src/service/sse/SSEService';
import { FilterCreate, Filter, Event } from '@eencloud/eewc-components/src/service/api-types';
import api from '@eencloud/eewc-components/src/service/api';
import { useCamerasStore } from './cameras';
import { useAccountStore } from './account';
import { useBridgesStore } from './bridges';

// Default events to be included in the subscription.
export const DEFAULT_SUBSCRIPTION_EVENTS = [
  'een.layoutCreationEvent.v1',
  'een.layoutDeletionEvent.v1',
  'een.layoutUpdateEvent.v1',
];

export const useSSEStore = defineStore('sse', () => {
  // Store references and state variables
  const accountStore = useAccountStore();
  const eventsStore = useEventsStore();
  const camerasStore = useCamerasStore();
  const bridgesStore = useBridgesStore();
  const sseStream = ref<SSEService | undefined>(undefined);

  let createdFilters: { type: string; filter: Filter }[] = [];
  let pendingSubscriptionQueue: { type: string; filter: FilterCreate }[] = [];
  let eventSubscriptionId: string | undefined = undefined;

  /**
   * Computes the default filter for creating subscriptions.
   * The filter is based on the current user's account ID and predefined events.
   */
  const defaultSubscriptionCreateFilter = computed<[FilterCreate] | undefined>(() => {
    if (!accountStore.account?.id) return;
    return [
      {
        actors: ['account:' + accountStore.account?.id],
        types: DEFAULT_SUBSCRIPTION_EVENTS.map((event) => ({ id: event })),
      },
    ];
  });

  /**
   * Watches the `sseStream` reference and triggers subscription data to be sent
   * when a new SSE stream is initialized.
   */
  watch(
    () => sseStream.value,
    (newVal, oldVal) => {
      if (newVal && !oldVal) {
        sendSubscriptionData();
      }
    }
  );

  /**
   * Sets up an event subscription for SSE.
   * Sends the subscription request to the API and initializes the SSE stream.
   */
  async function setSubscription() {
    const res = await eventsStore.createEventSubscription({
      deliveryConfig: {
        type: 'serverSentEvents.v1',
      },
      filters: defaultSubscriptionCreateFilter.value,
    });

    if (!res) return;

    eventSubscriptionId = res.id;
    sseStream.value = new SSEService(res.deliveryConfig.sseUrl, 'GET');

    // Set up message and error handlers for the SSE stream.
    sseStream.value.onMessage((event) => {
      if (!event.data) return; // ignore empty events, for example heartbeat messages
      processSSEResponse(event.data as Event);
    });

    sseStream.value.onError((event) => {
      console.error(event);
    });
  }

  function processSSEResponse(event: Event) {
    // Process the event data from the SSE stream.
    switch (event.actorType) {
      case 'camera':
        camerasStore.processSSEResponse(event);
        break;
      case 'bridge':
        bridgesStore.processSSEResponse(event);
        break;
    }
  }
  /**
   * Unsubscribes a actor by its type.
   * Removes pending subscriptions or deletes the filter if already created.
   *
   * @param {string} type - The type of the actor to unsubscribe.
   */
  async function unSubscribeActors(type: string) {
    const notSubscribed = pendingSubscriptionQueue.find((item) => item.type !== type);
    if (notSubscribed) {
      pendingSubscriptionQueue = pendingSubscriptionQueue.filter((item) => item.type !== type);
      return;
    }

    const createdFilter = createdFilters.find((item) => item.type === type);
    if (eventSubscriptionId && createdFilter?.filter.id) {
      const res = await api.deleteEventSubscriptionFilter(eventSubscriptionId, createdFilter.filter.id);
      if (res) {
        createdFilters = createdFilters.filter((item) => item.type !== type);
      }
    }
  }

  /**
   * Subscribes a actor by its type and filter.
   * Adds the filter to a pending queue and triggers the sending of subscription data.
   *
   * @param {string} type - The type of the actor to subscribe.
   * @param {FilterCreate} filter - The filter configuration for the subscription.
   */
  async function subscribeActors(type: string, filter: FilterCreate) {
    if (filter.actors.length === 0) return; // ignore empty actors
    pendingSubscriptionQueue.push({ type, filter });
    sendSubscriptionData();
  }

  /**
   * Sends pending subscription data to the API.
   * Processes the `pendingSubscriptionQueue` and creates filters for each request.
   */
  async function sendSubscriptionData() {
    if (!eventSubscriptionId) return;

    pendingSubscriptionQueue.forEach(async (request) => {
      const res = await api.createEventSubscriptionFilter(eventSubscriptionId, request.filter);
      if (res) {
        createdFilters.push({ type: request.type, filter: res });
        pendingSubscriptionQueue = pendingSubscriptionQueue.filter((f) => f.type !== request.type);
      }
    });
  }

  /**
   * Closes the SSE subscription.
   * Cleans up the SSE stream by closing it and resetting its reference.
   */
  function closeSubscription() {
    if (sseStream.value) {
      sseStream.value.close();
      sseStream.value = undefined;
    }
  }

  // Return public methods and state for the store.
  return { setSubscription, subscribeActors, unSubscribeActors, closeSubscription };
});
