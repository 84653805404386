const YOUTUBE_URL = 'https://www.youtube.com/watch?v=Gq87rP-NW5M';
const GUIDE_LINK = 'https://www.een.com/docs/app-notes/an039/';
const GATEWAY_GUIDE_LINK = 'https://www.een.com/docs/app-notes/an039/';

const DEFAULT_CAMERAS_COUNT_PER_ROW = 3;
const DEFAULT_ASPECT_RATIO = '16x9';
const DEFAULT_SHOW_TITLE = false;
const LAST_USED_APP = 'lastUsedWebApp';

enum WEB_INTERFACE {
  CLASSIC = 'classic',
  ENHANCED = 'enhanced',
}
const CLASSIC_WEB_APP_URL = 'http://login.eagleeyenetworks.com';

export {
  YOUTUBE_URL,
  GUIDE_LINK,
  GATEWAY_GUIDE_LINK,
  DEFAULT_CAMERAS_COUNT_PER_ROW,
  DEFAULT_ASPECT_RATIO,
  DEFAULT_SHOW_TITLE,
  WEB_INTERFACE,
  CLASSIC_WEB_APP_URL,
  LAST_USED_APP,
};
