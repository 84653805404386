import { computed, ref, onMounted, onBeforeUnmount, watch } from 'vue';
import useCookie from '@eencloud/eewc-components/src/service/useCookie.ts';
import { useUsersStore, useAuthStateStore } from '@/stores';
import { useRoute } from 'vue-router/composables';

export function useAppcues() {
  const usersStore = useUsersStore();
  const authStateStore = useAuthStateStore();
  const { getCookie } = useCookie();
  const route = useRoute();

  const bannerHeight = ref<number>(0);

  const currentRoutePath = computed(() => route.path);
  const userContext = computed(() => usersStore.userContext);
  const getMarginTopStyle = computed(() => {
    return { marginTop: `${bannerHeight.value}px` };
  });

  const getNavigationStyle = computed(() => {
    const marginTop = bannerHeight.value ? `${bannerHeight.value}px` : '0';
    const height = bannerHeight.value ? `calc(100vh - ${bannerHeight.value}px)` : '100vh';

    return { marginTop, height };
  });

  const getContainerStyle = computed(() => {
    const marginTop = bannerHeight.value ? `${bannerHeight.value - 56}px` : '0';

    return { marginTop };
  });

  function configureAppcues() {
    if (!window.Appcues) return;

    const combinedUserData = {
      ...userContext.value,
      browser: navigator.userAgent,
      os: window.navigator.userAgentData?.platform,
      product: 'VMS',
      application: 'WebApp',
      platform: 'web',
      appVersion: config.version,
      language: getCookie('lang'),
      environment: config.is_production ? 'production' : 'development',
    };

    window.Appcues.identify(userContext.value?.userId, combinedUserData);
  }

  // Function to calculate the height of all banners
  function calculateBannerHeight() {
    bannerHeight.value = 0; // Reset height

    // Select all banners matching the Appcues container pattern
    const banners = document.querySelectorAll<HTMLElement>('[id^="appcues-container"]');

    // If no banners are present, ensure height remains 0
    if (!banners.length) {
      bannerHeight.value = 0;
      return;
    }

    let accumulatedHeight = 0;

    banners.forEach((banner, index) => {
      if (banner) {
        const rect = banner.getBoundingClientRect();
        const currentHeight = rect.height;

        // For banners beyond the first one, adjust their positioning
        if (index > 0) {
          Object.assign(banner.style, {
            position: 'fixed',
            top: `${accumulatedHeight}px`,
            width: '100%',
          });
        }

        accumulatedHeight += currentHeight;
        bannerHeight.value += currentHeight;
      }
    });
  }

  // Listen to Appcues events for real-time banner updates
  function handleAppcuesEvents() {
    if (!window.Appcues) {
      return;
    }
    // reset banner height
    bannerHeight.value = 0;

    window.Appcues.on('v2:experience_started', () => {
      calculateBannerHeight();
    });
  }

  // Initialize Appcues and configure user data
  async function initializeAppcues() {
    await usersStore.getUserContext(); // Fetch user context
    configureAppcues(); // Configure Appcues
    handleAppcuesEvents(); // Attach event listeners
  }

  watch(
    () => authStateStore.loggedIn,
    (newValue, oldValue) => {
      if (newValue !== oldValue && newValue) {
        initializeAppcues();
      }
    },
    { immediate: true }
  );

  watch(
    () => currentRoutePath.value,
    (newRoute, oldRoute) => {
      if (newRoute !== oldRoute) {
        handleAppcuesEvents();
      }
    }
  );

  return {
    bannerHeight,
    getMarginTopStyle,
    getNavigationStyle,
    getContainerStyle,
  };
}
