//./plugins/posthog.js
import posthog from 'posthog-js';

export default {
  install(Vue) {
    posthog.init('phc_ziFxZGCxvRMdAJ9HaygHhUCrkVxqKwO9usoEeoTDOPU', {
      api_host: 'https://us.i.posthog.com',
    });

    Vue.prototype.$posthog = posthog;
  },
};
