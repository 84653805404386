import { LAST_USED_APP } from '@/constants';
import { useUsersStore } from '@/stores';

export default function useUserPreference() {
  const usersStore = useUsersStore();

  async function getLastUsedApp() {
    const res = await usersStore.getUserPreference(LAST_USED_APP);

    return res?.data;
  }

  function updateLastUsedApp(value: string) {
    const payload = {
      data: {
        value: value,
      },
    };
    usersStore.updateUserPreference(LAST_USED_APP, payload);
  }

  return { getLastUsedApp, updateLastUsedApp };
}
